import { render, staticRenderFns } from "./Outside.vue?vue&type=template&id=77a4f85c&scoped=true&"
import script from "./Outside.vue?vue&type=script&lang=ts&"
export * from "./Outside.vue?vue&type=script&lang=ts&"
import style0 from "./Outside.vue?vue&type=style&index=0&id=77a4f85c&scoped=true&lang=css&"


/* normalize component */
import normalizer from "!../../node_modules/@vue/vue-loader-v15/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "77a4f85c",
  null
  
)

export default component.exports